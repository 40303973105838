import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterSelectModel, ItemSelectModel } from 'src/app/shared/components/filter-select/filter-select.model';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DepartmentService } from 'src/app/shared/services/API/admin-user/department.service';
import { AdminUserService } from 'src/app/shared/services/API/admin-user/admin-user.service';
import { ListAdminUserStruct } from 'src/app/shared/services/structs/admin-user/list-admin-user.struct';
import { UtilService } from 'src/app/shared/services/util.service';
import { UserDeleteModalComponent } from './user-delete-modal/user-delete-modal.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  constructor(private adminUserService: AdminUserService,
    private departmentService: DepartmentService,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog
  ) { }

  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.master;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.master_user;

  public listUser: ListAdminUserStruct[];
  public filterSelectModelProfession:FilterSelectModel;
  public filterSelectModelStatus:FilterSelectModel;
  public searchText: string;
  public isActive: boolean;
  public isLoading: boolean;


  ngOnInit(): void {
    this.populateScrean();

    this.listUser == null;
    this.isLoading = true;
    this.isActive = null;
    this.searchText = null;
  }

  populateScrean(){    
    //populando select profissões
    this.populateSelectProfession()

    //populando select de status
    this.filterSelectModelStatus = new FilterSelectModel();
    this.filterSelectModelStatus.itemSelectModel.push(new ItemSelectModel("true", "Ativo", false))
    this.filterSelectModelStatus.itemSelectModel.push(new ItemSelectModel("false", "Inativo", false))
    this.filterSelectModelStatus.itemSelectModel.push(new ItemSelectModel("null", "Ambos", false))
    
    //Listando usuários
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  populateSelectProfession(){
    this.filterSelectModelProfession = new FilterSelectModel();
    this.departmentService.listDepartment().subscribe((response)=>{
           
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        return;
      }

      response.listDepartment.forEach(element => {
        this.filterSelectModelProfession.itemSelectModel
        .push(new ItemSelectModel(element.idDepartment.toString(), element.departmentName, false));
      });
    },
    (error)=>{
      console.log(error)
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  search(){

    let jsonListIdDepartment = this.utilService.getKeySelectedModel(this.filterSelectModelProfession);

    if(this.searchText == "")
      this.searchText = null;

    this.isLoading = true;
    this.adminUserService.listAdminUser(this.isActive ,this.searchText, jsonListIdDepartment).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listUser = response.listAdminUser;  
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  openModal(idAdminUser){
    const dialogRef = this.dialog.open(UserDeleteModalComponent, {
      data: {
        idAdminUser: idAdminUser
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteUser){
        this.search();
      }
    });
  }
}