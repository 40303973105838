import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { InternalReportConfigResponse } from '../../responses/business-intelligence/internal-report-config.response';
import { InternalReportProfileResponse } from '../../responses/business-intelligence/internal-report-profile.response';
import { ListInternalReportProfileResponse } from '../../responses/business-intelligence/list-internal-report-profile.response';
import { ReportPreviewAccessResponse } from '../../responses/business-intelligence/report-preview-access.response';
import { ReportConfigResponse } from '../../responses/business-intelligence/report-config.response';
@Injectable({
    providedIn: 'root'
})
export class ReportHealthUnitService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient,) {
        super();
    }
    public base_uri: string = `ReportHealthUnit`;

    public getByReport(idReport: number): Observable<ReportPreviewAccessResponse> {
        let uri = this.base_uri + `/idReport/${idReport}`

        return this.httpClient.get<ReportPreviewAccessResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public getReport(idReportFunctionHealthUnit: number): Observable<ReportConfigResponse> {
        console.log(idReportFunctionHealthUnit);
        let uri = this.base_uri + `/idReportFunctionHealthUnit/${idReportFunctionHealthUnit}`
        return this.httpClient.get<ReportConfigResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}
