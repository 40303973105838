<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">
    <a class="back-link" routerLink="/consulting/care-line-priority">
        <mat-icon aria-hidden="false" aria-label="Plus">arrow_back</mat-icon> Linhas de Cuidado - Prioridade
    </a>
    <h1>Cadastrar Linha de Cuidado - Prioridade</h1>
    <app-loading-progress-bar *ngIf="isFirstLoading"></app-loading-progress-bar>
    <div class="white-body">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome</mat-label>
                        <input matInput type="text" formControlName="careLinePriorityName">
                        <mat-error *ngIf="model.get('careLinePriorityName').invalid">Informe o CNPJ</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Ícone</mat-label>
                        <mat-select (selectionChange)="setIsDefaultIcon($event)" [(ngModel)]="actualSelectedIcon"
                            [ngModelOptions]="{standalone: true}">
                            <mat-select-trigger>
                                <span *ngIf="this.isDefault" matTooltip="{{model.get('iconClass').value}}"
                                    class="material-symbols-outlined select-trigger">{{model.get('iconClass').value}}</span>
                                <img *ngIf="!this.isDefault" matTooltip="{{model.get('iconClass').value}}"
                                    src="{{selectedIconPath}}" class="img-adjust select-trigger" />
                                {{model.get('iconClass').value}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let item of listIcons" [value]="item" matTooltip="{{item.iconClass}}">
                                <span *ngIf="item.isDefaultIcon"
                                    class="material-symbols-outlined select-trigger">{{item.iconClass}}</span>
                                <img src="{{item.completeFileSrc}}" class="img-adjust select-trigger"
                                    *ngIf="!item.isDefaultIcon" />
                                {{item.iconClass}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('iconClass').invalid">Selecione um ícone</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                    <div formGroupName="firstChildGroup">
                        <mat-form-field appearance="outline">
                            <mat-label>Unidades de Saúde</mat-label>
                            <mat-select formControlName="listIdHealthUnit" [multiple]="true">
                                <div class="searchContainer">
                                    <input class="inputSearchSelect" type="text" [(ngModel)]="searchHealthUnit"
                                        [ngModelOptions]="{standalone: true}" (keyup)="onKeySearch($event.target.value)"
                                        placeholder="Pesquisar" autocomplete="off" (keydown)="$event.stopPropagation()">
                                </div>
                                <app-loading-progress-bar *ngIf="isLoading"></app-loading-progress-bar>
                                <mat-option *ngFor="let item of listHealthUnit; let i = index"
                                    [value]="item.idHealthUnit">
                                    {{ item.healthUnitName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="isActive">
                            <mat-option value="true">Ativo</mat-option>
                            <mat-option value="false">Inativo</mat-option>
                        </mat-select>
                        <mat-error *ngIf="model.get('isActive').invalid">Informe o Status</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <strong>Abrir protocolo na triagem?</strong>
                    <mat-slide-toggle class="toggle-protocol" formControlName="openProtocolTriage"
                        (change)="changeMode($event)"></mat-slide-toggle>
                    <div *ngIf="model.get('openProtocolTriage').value">
                        <div class="col-12 col-sm-8 col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome do protocolo</mat-label>
                                <input matInput type="text" formControlName="protocolName">
                                <mat-error *ngIf="model.get('protocolName').invalid">Informe o nome do
                                    protocolo</mat-error>

                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="model.get('openProtocolTriage').value">
                <div class="row col-12">
                    <div formArrayName="listNextStepsAfterTriageProtocol"
                        *ngFor="let item of model.get('listNextStepsAfterTriageProtocol')['controls']; let i = index"
                        class="col-12 col-lg-6">
                        <div [formGroupName]="i" class="personalize-array">
                            <div class="col-9">
                                <mat-form-field appearance="outline">
                                    <mat-label>Próximo passo pós abertura</mat-label>
                                    <input matInput type="text"
                                        formControlName="nextStepsAfterTriageProtocolDescription"
                                        [maxlength]="maxLengthNextSteps">
                                    <mat-hint align="end">{{item.get('nextStepsAfterTriageProtocolDescription').value.length}}/{{maxLengthNextSteps}}</mat-hint>
                                    <mat-error
                                        *ngIf="item.get('nextStepsAfterTriageProtocolDescription').invalid">Informe
                                        o
                                        próximo passo</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-3">
                                <button class="care-line btn-primary" mat-mini-fab type="button" (click)="addNext()">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                                </button>
                                <button class="care-line btn-primary" mat-mini-fab type="button"
                                    (click)="removeButton(i)" *ngIf="i != 0">
                                    <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row margin-button">
                <div class="col-md-2">
                    <button mat-button type="submit" class="btn-primary btn-block">
                        <span *ngIf="isLoading == false">Salvar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-md-2">
                    <a mat-stroked-button type="button" routerLink="/consulting/care-line-priority"
                        class="btn-secundary btn-block">
                        Cancelar
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>