import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GetCareLinePriorityService } from 'src/app/shared/services/API/care-line-priority/get-care-line-priority.service';
import { DiscriminatorSelectService } from 'src/app/shared/services/API/risk-classification/discriminator-select.service';
import { HealthGuidelineService } from 'src/app/shared/services/API/risk-classification/health-guideline.service';
import { ListFlowchartByIdService } from 'src/app/shared/services/API/risk-classification/list-flowchart-by-id.service';
import { LookupRiskService } from 'src/app/shared/services/API/risk-classification/lookup-risk.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { CareLinePriority } from 'src/app/shared/services/models/care-line-priority/care-line-priority.model';
import { HealthGuidelineCategorization } from 'src/app/shared/services/models/risk-classification/health-guideline-categorization.model';
import { HealthGuidelineRequest } from 'src/app/shared/services/requests/risk-classification/health-guideline.request';
import { GetHealthGuidelineResponse } from 'src/app/shared/services/responses/risk-classification/get-health-guideline.response';
import { SelectResponse } from 'src/app/shared/services/responses/risk-classification/select.response';
import { CounselingTypeStruct } from 'src/app/shared/services/structs/risk-classification/counseling-type.struct';
import { SelectStruct } from 'src/app/shared/services/structs/risk-classification/select.struct';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-health-guideline-register',
  templateUrl: './health-guideline-register.component.html',
  styleUrls: ['./health-guideline-register.component.css']
})
export class HealthGuidelineRegisterComponent implements OnInit, OnDestroy {

  constructor(private formBuilder: FormBuilder,
    private maskService: MaskService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private healthGuidelineService: HealthGuidelineService,
    private lookupRiskService: LookupRiskService,
    private getCareLinePriorityService: GetCareLinePriorityService,
    private listFlowchartByIdService: ListFlowchartByIdService,
    private discriminatorSelectService: DiscriminatorSelectService,
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.health_guideline;

  public model: FormGroup;
  public isLoading: boolean = true;
  public isFirstLoading: boolean;
  public isUpdate: boolean;
  public actualServiceModule: number;
  public listCounselingType:CounselingTypeStruct[];

  public masks: Masks;
  public idHealthGuideline: number;
  public healthGuidelineRequest: HealthGuidelineRequest = new HealthGuidelineRequest();
  public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";
  public urlListProtocol: string = environment.urlApiRiskClassification + 'ProtocolSelect?isAdmin=true';
  public listFlowchart: SelectStruct[];
  public listDiscriminator: SelectStruct[];
  public listCareLinePriority: CareLinePriority[];
  public discriminatorTimeout: any;
  public flowchartTimeout: any;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      name: [null, [Validators.required]],
      isActive: [true, [Validators.required]],

      firstChildGroup: this.formBuilder.group({
        listIdHealthUnit: [, [Validators.required]],
        listIdProtocol: [, [Validators.required]],
        listIdFlowchart: [, [Validators.required]],
        listIdDiscriminator: [, [Validators.required]],
      }),
      listCategorization: this.formBuilder.array([]),
    });

    this.masks = this.maskService.getMasks();
    this.populateProtocol();
    this.populateCareLinePrioritySelect();

    this.idHealthGuideline == null;
    this.isUpdate == false;
    if (this.activatedRoute.snapshot.paramMap.get('idHealthGuideline'))
      this.idHealthGuideline = parseInt(this.activatedRoute.snapshot.paramMap.get('idHealthGuideline'));

    if (this.idHealthGuideline != null) {
      this.isFirstLoading = true;
      this.isUpdate = true;

      this.populateHealthGuideline();

    }else this.addNext();
  }

  ngOnDestroy(): void {
    clearTimeout(this.discriminatorTimeout);
    clearTimeout(this.flowchartTimeout);
  }

  populateHealthGuideline() {
    this.healthGuidelineService.getGuideline(this.idHealthGuideline).subscribe({
      next: (response: GetHealthGuidelineResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get('name').setValue(response.healthGuidelineStruct.healthGuideline.healthGuidelineName);
        this.model.get('isActive').setValue(response.healthGuidelineStruct.healthGuideline.isActive.toString());
        this.model.get('firstChildGroup').get('listIdHealthUnit').setValue(response.healthGuidelineStruct.listIdHealthUnit);
        this.model.get('firstChildGroup').get('listIdProtocol').setValue(response.healthGuidelineStruct.listIdProtocol);
        this.model.get('firstChildGroup').get('listIdFlowchart').setValue(response.healthGuidelineStruct.listIdFlowchart);
        this.model.get('firstChildGroup').get('listIdDiscriminator').setValue(response.healthGuidelineStruct.listIdDiscriminator);
        this.listDiscriminator = response.listDiscriminator;
        this.listFlowchart = response.listFlowchart;
        response.healthGuidelineStruct.listHealthGuidelineCategorization.forEach(x => {
          (this.model.controls['listCategorization'] as FormArray).push(
            this.formBuilder.group({
              idHealthGuidelineCategorization: [x.idHealthGuidelineCategorization.toString()],
              categorizationName: [{ value: x.categorizationName, disabled: true }, [Validators.required]],
              description: [{ value: x.description, disabled: true }],
              idCareLinePriority: [{ value: x.idCareLinePriority, disabled: true }],
              useDatetimeEpisode: [x.useDatetimeEpisode ?? false, [Validators.required]],
            })
          )
        });

        this.model.get('name').disable();

        this.isLoading = false;
        this.isFirstLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  submit() {
    if(this.isLoading){
      return;
    }

    if(!this.model.valid) {
      this.alertService.show('Erro', "Todos os campos em vermelho devem ser corretamente preenchidos.", AlertType.error);
      return;
    }

    this.isLoading = true;

    this.healthGuidelineRequest.healthGuidelineName = this.model.get('name').value;

    this.healthGuidelineRequest.isActive = this.model.get('isActive').value === 'true' ? true : false;

    this.healthGuidelineRequest.listIdHealthUnit = this.model.get('firstChildGroup').get('listIdHealthUnit').value;
    this.healthGuidelineRequest.listIdProtocol = this.model.get('firstChildGroup').get('listIdProtocol').value;
    this.healthGuidelineRequest.listIdFlowchart = this.model.get('firstChildGroup').get('listIdFlowchart').value;
    this.healthGuidelineRequest.listIdDiscriminator = this.model.get('firstChildGroup').get('listIdDiscriminator').value;

    this.healthGuidelineRequest.listHealthGuidelineCategorization = []
    this.model.getRawValue().listCategorization.forEach(x => {
      var model = new HealthGuidelineCategorization();

      model.idHealthGuidelineCategorization = x.idHealthGuidelineCategorization;
      model.categorizationName = x.categorizationName;
      model.description = x.description;
      model.idCareLinePriority = x.idCareLinePriority;
      model.useDatetimeEpisode = x.useDatetimeEpisode;

      this.healthGuidelineRequest.listHealthGuidelineCategorization.push(model);
    });

    if (this.isUpdate)
      this.updateHealthGuideline();
    else
      this.createHealthGuideline();
  }

  updateHealthGuideline() {
    this.healthGuidelineService.updateGuideline(this.idHealthGuideline, this.healthGuidelineRequest).subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/consulting/health-guideline/list']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  createHealthGuideline() {
    this.healthGuidelineService.craeteGuideline(this.healthGuidelineRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Informações salvas com sucesso", AlertType.success);
        this.isLoading = false;

        this.router.navigate(['/consulting/health-guideline/list']);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateProtocol() {
    this.lookupRiskService.getLookup().subscribe({
      next:(response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listCounselingType = response.listCounselingType;

        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateCareLinePrioritySelect() {
    this.getCareLinePriorityService.getAllByIdHealthUnit().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listCareLinePriority = response.listAllCareLinePriority.filter(x => !x.isDeleted && x.isActive);
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateFlowchartSelect(listIdProtocol: number[]) {
    this.listFlowchartByIdService.listFlowchart(listIdProtocol).subscribe({
      next: (response: SelectResponse) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listFlowchart = response.list;
        if(!this.listFlowchart || this.listFlowchart.length == 0) {
          this.listDiscriminator = [];
          this.model.get('firstChildGroup').get('listIdFlowchart').setValue(null);
          this.model.get('firstChildGroup').get('listIdDiscriminator').setValue(null);
        }
        else{
          let listIdFlowchart: number[] = this.listFlowchart.map(x => x.id);
          const filteredArray = this.model.get('firstChildGroup').get('listIdFlowchart')?.value?.filter(value => listIdFlowchart.includes(value));
          this.model.get('firstChildGroup').get('listIdFlowchart').setValue(filteredArray);
          if(filteredArray && filteredArray.length > 0) {
            this.populateDiscriminatorSelect(filteredArray);
          }
        }
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateDiscriminatorSelect(listIdFlowchart) {
    this.discriminatorSelectService.listDiscriminator(listIdFlowchart).subscribe({
      next: (response: SelectResponse) => {
        if (response.isError) {
          this.alertDynamic('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.listDiscriminator = response.list;
        if(this.listDiscriminator.length == 0) {
          this.model.get('firstChildGroup').get('listIdDiscriminator').setValue(null);
        }
        else{
          let listIdDiscriminator: number[] = this.listDiscriminator.map(x => x.id);
          const filteredArray = listIdDiscriminator?.filter(value => this.model.get('firstChildGroup').get('listIdDiscriminator')?.value.includes(value));
          this.model.get('firstChildGroup').get('listIdDiscriminator').setValue(filteredArray);
        }
      },
      error: (error) => {
        this.alertDynamic('Erro inesperado', error, AlertType.error);
      }
    });
  }

  addNext() {
    (this.model.controls['listCategorization'] as FormArray).push(this.createInput());
  }

  removeButton(index: number) {
    (this.model.controls['listCategorization'] as FormArray).removeAt(index);
  }

  createInput() {
    return this.formBuilder.group({
      idHealthGuidelineCategorization: [0],
      categorizationName: ['', [Validators.required]],
      description: [''],
      idCareLinePriority: [null],
      useDatetimeEpisode: [false],
    });
  }

  alertDynamic(alertTypeDescription: string, alertDescription: string, alertType: AlertType, endLoading: boolean = false) {
    if (alertType && alertType.valueOf() == AlertType.error)
      console.log(alertDescription);

    this.alertService.show(alertTypeDescription, alertDescription, alertType ? alertType.valueOf() : AlertType.error);
    if (endLoading)
      this.isLoading = false;
  }

  selectedProtocol(event) {
    let listIdProtocol = event.map(x => x.id);
    this.listFlowchart = null;
    this.listDiscriminator = null;
    let $this = this;
    clearTimeout(this.flowchartTimeout)
    this.discriminatorTimeout = setTimeout(() => {
      $this.populateFlowchartSelect(listIdProtocol)
      clearTimeout($this.flowchartTimeout)
    }, 750);
  }

  selectedFlowchart(event) {
    let listIdFlowchart = event.map(x => x.id);
    this.listDiscriminator = null;
    let $this = this;
    clearTimeout(this.discriminatorTimeout)
    this.discriminatorTimeout = setTimeout(() => {
      $this.populateDiscriminatorSelect(listIdFlowchart)
      clearTimeout($this.discriminatorTimeout)
    }, 750);
  }
}
