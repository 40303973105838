import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { InternalReportConfigResponse } from '../../responses/business-intelligence/internal-report-config.response';
import { InternalReportProfileResponse } from '../../responses/business-intelligence/internal-report-profile.response';
import { ListInternalReportProfileResponse } from '../../responses/business-intelligence/list-internal-report-profile.response';
import { ReportPreviewAccessResponse } from '../../responses/business-intelligence/report-preview-access.response';
@Injectable({
  providedIn: 'root'
})
export class InternalReportProfileService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient,) {
    super();
  }

  public getReports(jsonProfile: number[]): Observable<InternalReportProfileResponse> {
    let uri = `InternalReportProfile`

    if (jsonProfile) {
      uri = uri + `?jsonProfile=${JSON.stringify(jsonProfile)}`
    }

    return this.httpClient.get<InternalReportProfileResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public getByInternalReport(idInternalReport: number): Observable<ReportPreviewAccessResponse> {
    let uri = `InternalReportProfile/idInternalReport/${idInternalReport}`

    return this.httpClient.get<ReportPreviewAccessResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }
}
