import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ListUserService } from 'src/app/shared/services/API/admin-user/list-user.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { ProcedureSigtapCompetenceModel } from 'src/app/shared/services/models/billing/procedure-sigtap-competence.model';
import { UtilService } from 'src/app/shared/services/util.service';
import { BillingSigtapDeleteModalComponent } from './billing-sigtap-delete-modal/billing-sigtap-delete-modal.component';
import { CompetenceSelectService } from 'src/app/shared/services/API/billing/competence-select.service';

@Component({
  selector: 'app-billing-sigtap-list',
  templateUrl: './billing-sigtap-list.component.html',
  styleUrls: ['./billing-sigtap-list.component.css']
})
export class BillingSigtapListComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private utilService: UtilService,
    private maskService: MaskService,
    private dialog: MatDialog,
    private competenceSelectService: CompetenceSelectService,
    private listUserService: ListUserService
  ) { }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.consulting_billing;
  public masks: Masks;
  public listBillingSigtap: ProcedureSigtapCompetenceModel[];
  public isLoading: boolean;
  public isFirstLoading: boolean = false;
  public model: FormGroup;
  public initial: string;
  public final: string;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.isFirstLoading = true;
    this.populateProcedures();
  }

  populateProcedures() {
    this.listBillingSigtap = [];
    this.isLoading = true && !this.isFirstLoading;
    let intialDate = null;
    let finalDate = null;
    if (this.initial) {
      intialDate = this.utilService.toISOFormat("01/" + this.initial);
    }
    if (this.final) {
      finalDate = this.utilService.toISOFormat("29/" + this.final);
    }
    this.competenceSelectService.listProcedureSigtapCompetence(intialDate, finalDate).subscribe((response) => {
      if (response.isError) {
        this.isFirstLoading = false;
        this.isLoading = false;
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
      }
      if (response.procedureSigtapCompetences.length > 0) {
        this.listUserService.listAdminUserByIds(response.listUserAdmin).subscribe((users) => {
          this.isFirstLoading = false;
          this.isLoading = false;
          if (users.isError) {
            this.alertService.show("Erro", users.errorDescription, AlertType.error);
          }
          response.procedureSigtapCompetences.forEach((c) => {
            let userAdmin = users.listAdminUser.find(x => x.idAdminUser == c.idAdminUser);
            c.adminUserName = userAdmin ? userAdmin.adminUserName : "";
          });
          this.listBillingSigtap = response.procedureSigtapCompetences;
        });
      } else {
        this.isFirstLoading = false;
        this.isLoading = false;
      }
    });
  }

  openModal(idProcedureSigtapCompetence: number) {
    const dialogRef = this.dialog.open(BillingSigtapDeleteModalComponent, {
      data: {
        idProcedureSigtapCompetence: idProcedureSigtapCompetence
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.deleteUser) {
        this.listBillingSigtap = [];
        this.populateProcedures();
      }
    });
  }
}
