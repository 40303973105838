import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LogVisualizerService } from 'src/app/shared/services/API/srvlog/log-visualizer.service';
import { HealthUnitService } from 'src/app/shared/services/API/user/health-unit.service';
import { ListHealthUnitStruct } from 'src/app/shared/services/structs/admin-user/list-health-unit.struct';
import { ListLogStruct } from 'src/app/shared/services/structs/srvlog/list-log.struct';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-log-visualizer-list',
  templateUrl: './log-visualizer-list.component.html',
  styleUrls: ['./log-visualizer-list.component.css']
})
export class LogVisualizerListComponent implements OnInit, OnDestroy {

  constructor(private alertService: AlertService,
    private healthUnitService: HealthUnitService,
    private logVisualizerService: LogVisualizerService) { }
  

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.developer;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.log_list;
  public isLoading: boolean;
  public listLogs: ListLogStruct[];
  public listHealthUnit: ListHealthUnitStruct[];
  public fullListSize: number;
  public searchText: string;
  public beginDate: Date;
  public idLog: number;
  public type: string;
  public endDate: Date;
  public idHealthUnit: number;
  private interval: any;
  public isPanelMode: boolean;
  public latestId: number;
  public urlListHealthUnit: string = environment.urlApiUser + "ListHealthUnit";
  public listIdHealthUnit: number[] = [];

  ngOnInit(): void {

    this.populateHealthUnitSelect();
    this.latestId = 0;
    this.search(0);
  }

  search(pageIndex: number) {
    this.isLoading = true;

    if (this.searchText == "")
      this.searchText = null;

    if (this.beginDate)
      this.beginDate.setHours(0);

    if (this.endDate) {
      this.endDate.setHours(23);
      this.endDate.setMinutes(59);
    }

    this.logVisualizerService.listLogWithListHealthUnit(this.idLog, this.listIdHealthUnit, this.type, this.searchText, this.beginDate, this.endDate, pageIndex).subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      if(this.latestId < response.listLogs[0]?.idLog) {
        this.latestId = response.listLogs[0]?.idLog;
        if(this.isPanelMode) {
          let audio = new Audio();
          audio.src = "https://storage.googleapis.com/tolife-production-public-storage/admin_error_hey_listen.mp3";
          audio.load();
          audio.play();
        }
      }
      this.fullListSize = response.fullListSize;
      this.listLogs = response.listLogs;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  changePage(event: PageEvent) {
    this.search(event.pageIndex);
  }

  buttonSearch() {
    if (this.isLoading)
      return;

    this.paginator.firstPage();
    this.search(0);
  }

  populateHealthUnitSelect() {
    this.healthUnitService.listHealthUnit().subscribe((response) => {
      if (response.isError) {
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      this.listHealthUnit = response.listHealthUnit;
      this.isLoading = false;
    },
      (error) => {
        console.log(error)
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
  }

  getHealthUnitName(idHealthUnit: number): string {
    if (idHealthUnit == -1)
      return "Admin";

    if (!this.listHealthUnit || this.listHealthUnit.length <= 0)
      return "";

    return this.listHealthUnit.find(l => l.idHealthUnit == idHealthUnit)?.healthUnitName;
  }

  changeMode(event: any) {
    if (event.checked) {
      var $this = this;
      this.interval = setInterval(function () {
        $this.search(0);
        if (!$this.isPanelMode) clearInterval($this.interval);
      }, 10000);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  selectHealthUnit(event){
    this.listIdHealthUnit = event.map(x => x.idHealthUnit);
  }
}