<div mat-dialog-content>
    <div class="mat-dialog-content">
        <mat-form-field *ngIf="!idAccess" appearance="fill">
            <mat-label>Escolha um perfil</mat-label>
            <mat-select (selectionChange)="renderPreview($event)" [(ngModel)]="idAccess">
                <mat-option *ngFor="let option of reportAccessOptions" [value]="option.idAccess">
                    {{option.accessName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="idAccess" class="report-container">
            <div class="white-body">
                <div *ngIf="!isFirstLoading" class="container-powerbi">
                    <div id="reportDiv" class="div-report" #reportDiv></div>
                </div>
            </div>
        </div>
    </div>
</div>