import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, catchError } from "rxjs";
import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { environment } from "src/environments/environment";
import { CommonService } from "../../common-service";
import { PostPutBatchConfigurationRequest } from "../../requests/srv-log/post-put-batch-configuration.request";
import { GetBatchConfigurationResponse } from "../../responses/srvlog/get-batch-configuration.response";
import { ListBatchConfigurationResponse } from "../../responses/srvlog/list-batch-configuration.response";
import { ListBatchMonitoringResponse } from "../../responses/srvlog/list-batch-monitoring.response";
import { BatchMonitoringResponse } from "../../responses/srvlog/batch-monitoring.response";

@Injectable({
    providedIn: 'root'
})
export class BatchMonitoringService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient) {
        super();
    }


    public listBatch(): Observable<ListBatchMonitoringResponse> {

        let uri = 'BatchMonitoring'

        return this.httpClient.get<ListBatchMonitoringResponse>(environment.urlApiSrvLog + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }

    public getProjectLogs(
        idBatchConfig: number,
        status: number = null,
        searchText: string = null,
        start: string = null,
        end: string = null,
        pageIndex: number = 0): Observable<BatchMonitoringResponse> {

        let uri = `BatchMonitoring/idBatchConfig/${idBatchConfig}?`;

        if (status)
            uri += `status=${status}&`
        if (searchText)
            uri += `searchText=${searchText}&`
        if (start)
            uri += `start=${start}&`
        if (end)
            uri += `end=${end}&`

        uri += `pageIndex=${pageIndex}`

        return this.httpClient.get<BatchMonitoringResponse>(environment.urlApiSrvLog + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            )
    }
}
