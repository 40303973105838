import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterSelectModel, ItemSelectModel } from 'src/app/shared/components/filter-select/filter-select.model';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { EmergesService } from 'src/app/shared/services/API/emerges-key/emerges.service';
import { EmergesModel } from "src/app/shared/services/models/emerges-key/emerges.model";
import { UtilService } from 'src/app/shared/services/util.service';
import { EmergesDeleteModalComponent } from './emerges-delete-modal/emerges-delete-modal.component';

@Component({
  selector: 'app-emerges-list',
  templateUrl: './emerges-list.component.html',
  styleUrls: ['./emerges-list.component.css']
})

export class EmergesListComponent implements OnInit {

  constructor(private emergesService: EmergesService,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog
  ) { }

  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.support;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.support_emerges;

  public listEmerges: EmergesModel[];
  public searchText: string;
  public isLoading: boolean;


  ngOnInit(): void {
    this.populateScrean();

    this.listEmerges == null;
    this.isLoading = true;
    this.searchText = null;
  }

  populateScrean(){
    //Listando emerges
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  search(){

    if(this.searchText == "")
      this.searchText = null;

    this.isLoading = true;
    this.emergesService.listEmerges(this.searchText).subscribe((response)=>{
      
      if(response.isError){
        this.alertService.show('Erro', response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
      
      this.listEmerges = response.listEmerges;  
      this.isLoading = false;    
    },
    (error)=>{
      console.log(error)
      this.isLoading = false;    
      this.alertService.show('Erro inesperado', error, AlertType.error);
    });
  }

  openModal(idEmerges){
    const dialogRef = this.dialog.open(EmergesDeleteModalComponent, {
      data: {
        idEmerges: idEmerges
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteEmerges){
        this.search();
      }
    });
  }
}