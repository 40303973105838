import { Component, ElementRef, Inject, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReportSourceModalComponent } from 'src/app/business-intelligence/pages/report-list/report-source-modal/report-source-modal.component';
import { AlertService, AlertType } from '../../services/alert.service';
import { InternalReportProfileService } from '../../services/API/business-intelligence/internal-report-profile.service';
import { ListProfileStruct } from '../../services/structs/admin-user/list-profile.struct';
import { ReportPreviewAccessStruct } from '../../services/structs/business-intelligence/report-preview-access.struct';
import { ReportHealthUnitService } from '../../services/API/business-intelligence/report-health-unit.service';
import { ReportScopeEnum } from '../../enum/report/report-scope.enum';
import { ListHealthUnitStruct } from '../../services/structs/admin-user/list-health-unit.struct';
import { ReportPreviewService } from '../../services/API/business-intelligence/report-preview.service';

declare var powerbi: any;

@Component({
  selector: 'app-report-preview-modal',
  templateUrl: './report-preview-modal.component.html',
  styleUrls: ['./report-preview-modal.component.css']
})
export class ReportPreviewModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<ReportSourceModalComponent>,
    private alertService: AlertService,
    private internalReportService: InternalReportProfileService,
    private externalReportService: ReportHealthUnitService,
    private reportPreviewService: ReportPreviewService,
  ) { }

  public isFirstLoading: boolean = true;
  public reportAccessOptions: ReportPreviewAccessStruct[] = [];
  public idAccess: number = null;
  public listProfileLookup: ListProfileStruct[] = [];
  public listHealthUnitLookup: ListHealthUnitStruct[] = [];
  public idReport: number = null;
  public idReportScope: number = null;
  public models;

  public internalReportName: string;
  public config = {
    type: 'report',
    tokenType: 1,
    accessToken: undefined,
    embedUrl: undefined,
    id: undefined,
    pageName: undefined,
    permissions: 0,
    viewMode: 0,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      layoutType: 1 // Set the default layout type to Master
      // panes:{
      //   pageNavigation: {
      //       visible: true,
      //   }
      // }    
    }
  };

  @ViewChildren('reportDiv') reportDiv: QueryList<ElementRef>;

  ngOnInit(): void {
    if (!this.data.idReport) {
      this.alertService.show('Erro', "Para conseguir ver o relatório precisa do seu id", AlertType.error);
      this.matDialogRef.close({ error: true });
    }
    console.log(this.data);
    this.listProfileLookup = this.data.listProfileLookup;
    this.listHealthUnitLookup = this.data.listHealthUnitLookup;
    this.idReport = this.data.idReport;
    this.idReportScope = this.data.idReportScope;

    if (this.idReportScope == ReportScopeEnum.Externo) {
      this.populateExternalReportAccess();
      console.log(`Report is external with id: ${this.idReport}`)
    }
    else if (this.idReportScope == ReportScopeEnum.Interno) {
      this.populateInternalReportAccess();
      console.log(`Report is internal with id: ${this.idReport}`)
    }
  }

  renderPreview(event: any) {
    let idReportAccess: number = parseInt(event.value);
    this.models = window['powerbi-client'].models;

    if (this.idReportScope == ReportScopeEnum.Interno)
      this.getInternalReport(idReportAccess);
    else if (this.idReportScope == ReportScopeEnum.Externo)
      this.getExternalReport(idReportAccess);
  }

  populateInternalReportAccess() {
    this.internalReportService.getByInternalReport(this.idReport).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        console.log(response);
        response.listAccess.forEach(x => {
          x.accessName = this.listProfileLookup.find(y => y.idProfile == x.idProfile)?.profileName;
          this.reportAccessOptions.push(x);
        });

      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  populateExternalReportAccess() {
    this.externalReportService.getByReport(this.idReport).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
        console.log(response);
        response.listAccess.forEach(x => {
          x.accessName = this.listHealthUnitLookup.find(y => y.idHealthUnit == x.idHealthUnit)?.healthUnitName;
          this.reportAccessOptions.push(x);
        });

      },
      error: (error) => {
        console.log(error)
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  getInternalReport(idInternalReportFunctionProfile: number) {
    this.reportPreviewService.getInternalReport(idInternalReportFunctionProfile).subscribe((response) => {
      this.isFirstLoading = false;
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        this.matDialogRef.close({ error: true });
        return;
      }
      console.log("Internal report response: ", response);
      this.config.embedUrl = response.internalReport.internalReportLink;
      this.config.id = response.internalReport.biReport;
      this.config.accessToken = response.internalReport.token;
      this.internalReportName = response.internalReport.internalReportName;

      const isMobile = window.innerWidth < 768; // Check if the page width is less than 768px (adjust the threshold as needed)
      if (isMobile) {
        this.config.settings.layoutType = this.models.LayoutType.MobilePortrait; // Set the layout type to MobilePortrait for mobile devices
      } else {
        this.config.settings.layoutType = this.models.LayoutType.Master; // Set the layout type to Custom for non-mobile devices
      }

      this.reportDiv.changes.subscribe(c => {
        c.toArray().forEach(item => {
          let report = powerbi.embed(item.nativeElement, this.config);
          report.on('loaded', function () {
            report.getPages().then(function (pages) {
              let page = pages[parseInt(response.internalReport.page) - 1];
              if (page) page.setActive();
            });
          });
        })
      });
    })
  }
  getExternalReport(idAccess: number) {
    this.reportPreviewService.getExternalReport(idAccess).subscribe((response) => {
      this.isFirstLoading = false;
      if (response.isError) {
        this.alertService.show("Erro", response.errorDescription, AlertType.error);
        this.matDialogRef.close({ error: true });
        return;
      }
      console.log("External report response: ", response);
      this.config.embedUrl = response.report.reportLink;
      this.config.id = response.report.biReport;
      this.config.accessToken = response.report.token;
      this.internalReportName = response.report.reportName;

      const isMobile = window.innerWidth < 768; // Check if the page width is less than 768px (adjust the threshold as needed)
      if (isMobile) {
        this.config.settings.layoutType = this.models.LayoutType.MobilePortrait; // Set the layout type to MobilePortrait for mobile devices
      } else {
        this.config.settings.layoutType = this.models.LayoutType.Master; // Set the layout type to Custom for non-mobile devices
      }

      this.reportDiv.changes.subscribe(c => {
        c.toArray().forEach(item => {
          let report = powerbi.embed(item.nativeElement, this.config);
          report.on('loaded', function () {
            report.getPages().then(function (pages) {
              let page = pages[parseInt(response.report.page) - 1];
              if (page) page.setActive();
            });
          });
        })
      });
    })
  }
}
