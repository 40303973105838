import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { CareLinePriorityService } from 'src/app/shared/services/API/care-line-priority/care-line-priority.service';
import { CareLinePriority } from 'src/app/shared/services/models/care-line-priority/care-line-priority.model';
import { CareLinePriorityDeleteModalComponent } from './care-line-priority-delete-modal/care-line-priority-delete-modal.component';
import { CareLinePriorityHealthGuidelineService } from 'src/app/shared/services/API/orchestrator-classification/care-line-priority-health-guideline.service';
import { HealthGuidelineTriageService } from 'src/app/shared/services/API/risk-classification/health-guideline-triage.service';
import { HealthGuidelineTriageResponse } from 'src/app/shared/services/responses/risk-classification/health-guideline-triage.response';
import { ValidateHealthGuidelineModalComponent } from './validate-health-guideline-modal/validate-health-guideline-modalcomponent';
import { HealthGuidelineTriageStruct } from 'src/app/shared/services/structs/risk-classification/health-guideline-triage.struct';

@Component({
  selector: 'app-care-line-priority-list',
  templateUrl: './care-line-priority-list.component.html',
  styleUrls: ['./care-line-priority-list.component.css']
})

export class CareLinePriorityListComponent implements OnInit {

  constructor(
    private careLinePriorityService: CareLinePriorityService,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog,
    public careLinePriorityHealthGuidelineService: CareLinePriorityHealthGuidelineService,
    public healthGuidelineTriageService: HealthGuidelineTriageService,
  ) { }

  public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.consulting;
  public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.care_line_priority;

  public listCareLinePriority: CareLinePriority[] = [];
  public searchText: string;
  public isLoading: boolean;
  public listHealthGuidelineTriageStruct: HealthGuidelineTriageStruct[];


  ngOnInit(): void {
    this.populateScrean();

    this.isLoading = true;
    this.searchText = null;
  }

  populateScrean(){
    //Listando CareLinePriority
    this.search();
  }

  private timeoutKeySearch: any = null;

  onKeySearch(event: any) {
    clearTimeout(this.timeoutKeySearch);
    var $this = this;
    this.timeoutKeySearch = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.search();
      }
    }, 1000);
  }

  search(){

    if(this.searchText == "")
      this.searchText = null;

    this.isLoading = true;
    this.careLinePriorityService.listCareLinePriority(this.searchText).subscribe({
      next: (response)=>{
      
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.listCareLinePriority = response.listCareLinePriority;  
        this.isLoading = false;    
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openDeleteModal(idCareLinePriority){
    const dialogRef = this.dialog.open(CareLinePriorityDeleteModalComponent, {
      data: {
        idCareLinePriority: idCareLinePriority,
        listIdHealthGuidelineCategorization: this.listHealthGuidelineTriageStruct.map(x => x.idHealthGuidelineCategorization)
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteCareLinePriority){
        this.search();
      }
    });
  }

  validateHealthGuideline(idCareLinePriority: number){
    this.isLoading = true;
    this.healthGuidelineTriageService.verifyCategorization(idCareLinePriority).subscribe({
      next: (response: HealthGuidelineTriageResponse)=>{
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        this.listHealthGuidelineTriageStruct = response.listHealthGuidelineTriageStruct;
        if(this.listHealthGuidelineTriageStruct && this.listHealthGuidelineTriageStruct.length >0)
          this.openValidateModal(idCareLinePriority);
        else{
          this.openDeleteModal(idCareLinePriority);
        }

        this.isLoading = false;    
      },
      error: (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

  openValidateModal(idCareLinePriority){

    const dialogRef = this.dialog.open(ValidateHealthGuidelineModalComponent, {
      data: {
        idCareLinePriority: idCareLinePriority,
        listHealthGuidelineTriageStruct: this.listHealthGuidelineTriageStruct,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.deleteCareLinePriority){
        this.openDeleteModal(idCareLinePriority);
      }
    });
  }
}