import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProfileService } from 'src/app/shared/services/API/admin-user/profile.service';
import { ListProfileStruct } from 'src/app/shared/services/structs/admin-user/list-profile.struct';
import { UtilService } from 'src/app/shared/services/util.service';

import { ProfileDeleteModalComponent } from './profile-delete-modal/profile-delete-modal.component';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.css']
})
export class ProfileListComponent implements OnInit {
  
  constructor(private profileService: ProfileService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog) { }
    
    public menuModuleEnum:MenuModuleEnum = MenuModuleEnum.master;
    public menuFunctionalityEnum:MenuFunctionalityEnum = MenuFunctionalityEnum.master_profile;
    
    public listProfile: ListProfileStruct[];
    public isLoading: boolean;
    public searchText: string;
    
    ngOnInit(): void {
      
      this.isLoading = true;
      this.populateScreen();
    }
    
    populateScreen(){
      //Listando perfis
      this.search();
    }
    
    private timeoutKeySearch: any = null;
    
    onKeySearch(event: any) {
      clearTimeout(this.timeoutKeySearch);
      var $this = this;
      this.timeoutKeySearch = setTimeout(function () {
        if (event.keyCode != 13) {
          $this.search();
        }
      }, 1000);
    }
    
    search(){
      this.isLoading = true;  

      if(this.searchText == "")
      this.searchText = null;
      
      this.profileService.listProfile(this.searchText).subscribe((response)=>{
        
        if(response.isError){
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
        
        this.listProfile = response.listProfile;  
        this.isLoading = false;    
      },
      (error)=>{
        console.log(error)
        this.isLoading = false;    
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });
    }

    openModal(idProfile){
      const dialogRef = this.dialog.open(ProfileDeleteModalComponent, {
        data: {
          idProfile: idProfile
        },
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result && result.deleteUser){
          this.search();
        }
      });
    }
  }
  