import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ReportPreviewAccessResponse } from '../../responses/business-intelligence/report-preview-access.response';
import { ReportConfigResponse } from '../../responses/business-intelligence/report-config.response';
import { InternalReportConfigResponse } from '../../responses/business-intelligence/internal-report-config.response';
@Injectable({
    providedIn: 'root'
})
export class ReportPreviewService extends CommonService {

    constructor(private router: Router, private httpClient: HttpClient,) {
        super();
    }
    public base_uri: string = `ReportPreview`;

    public getExternalReport(idReportFunctionHealthUnit: number): Observable<ReportConfigResponse> {
        console.log(idReportFunctionHealthUnit);
        let uri = this.base_uri + `/idReportFunctionHealthUnit/${idReportFunctionHealthUnit}`;
        return this.httpClient.get<ReportConfigResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }
    public getInternalReport(idInternalReportFunctionProfile: number): Observable<InternalReportConfigResponse> {
        console.log(idInternalReportFunctionProfile);
        let uri = this.base_uri + `/idInternalReportFunctionProfile/${idInternalReportFunctionProfile}`;
        return this.httpClient.get<InternalReportConfigResponse>(environment.urlApiBusinessIntelligence + uri, this.addHeaderToken())
            .pipe(
                catchError(this.handleError)
            );
    }
}
