import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { ListProfileResponse } from '../../responses/user/list-profile.response';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public GetListByIdHealthUnit(idHealthUnit: number): Observable<ListProfileResponse> {

    let uri = `Profile/idHealthUnit/${idHealthUnit}`

    return this.httpClient.get<ListProfileResponse>(environment.urlApiUser + uri, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}